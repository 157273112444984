import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Select from 'components/Select'
import ModelCard from 'components/ModelCard'
import PartnersLinks from 'components/PartnersLinks'

import styles from './ModelList.module.scss'

const PopularModelsPage = ({ data }) => {
  const { models, site: { siteMetadata }, banner } = data

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/models/`,
          "name": "Models"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/models/popular/`,
          "name": "Most popular"
        }
      },
    ]
  }

  const sloppyPartners = [
    {name: 'SONXXX', url: 'https://xxxvideoson.com/category/sonxxx/'},
  ]
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Choose your favorite webcam girl and join her live sex cam show | SloppyDeep`}
      description={`Discover the most popular live cam models right here on the best cam girl tube. SloppyDeep has the biggest archive of cam models ready to have fun with you all night. Choose your favorite and start the live show now!`}
      keywords={`best cam models, hottest cam girls, best cam girl videos, camgirl tube, hottest cam girls, most popular camgirls`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/models/popular/`}
      robots='index, follow'
    />
    <main className={`${styles.modelListScreen} screen-container`}>
      <div className={styles.titleBlock}>
        <div className={styles.sectionTitle}>Our Models</div>
        <div className={styles.sectionFilter}>
          <Select selected={1} options={[{ text: 'Latest', to: '/models/' }, { text: 'Popular', to: '/models/popular/' }]} />
        </div>
      </div>
      <div className={`${styles.modelsBlock} model-cards-container`}>
        {models.nodes.map((model, idx) => <ModelCard key={idx} data={model} />)}
      </div>

      {sloppyPartners.length > 0 &&
        <>
          <div className={styles.titleBlock}>Sloppy Partners</div>
          <div className={styles.partnersLinksBlock}>
            <PartnersLinks links={sloppyPartners} />
          </div>
        </>
      }

    </main>
    </>
  )
}

export const query = graphql`
  query PopularModelsPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    models: allSloppyDeepModel(sort: {fields: rating, order: DESC}) {
      nodes {
        slug
        primaryAka {
          nickname
        }
        profilePicture {
          childImageSharp {
            fluid(maxWidth: 353, maxHeight: 450, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        rating
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default PopularModelsPage
